<template>
  <div class="exam-display">
    <div
      v-if="listaPiatti.length > 0 && listaPiatti[0].NomeEsame"
      style="text-align: center; padding-bottom: 2vw; font-size: 3vw; font-weight: bold"
    >
      {{ listaPiatti[0].NomeEsame }}
    </div>
    <div v-if="listaPiatti.length > 0" class="tables-container">
      <div v-for="piatto of listaPiatti" :key="piatto.Tavolo" class="table-card">
        <div class="table-header">
          <h2 class="table-order">#{{ piatto.Ordine }}</h2>
          <h2 class="table-number">Tavolo {{ piatto.Tavolo }}</h2>
        </div>
        <p class="table-info" style="font-style: italic">"{{ piatto.NomePiatto }}"</p>
        <p class="table-info">
          {{ piatto.CognomeCandidato + " " + piatto.NomeCandidato }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplaySala",
  props: {
    piatti: Array,
  },
  computed: {
    listaPiatti() {
      let listaPiatti = [...this.piatti];
      listaPiatti.sort((a, b) => a.Tavolo - b.Tavolo);
      let mapTav = new Map();
      for (let p of listaPiatti) {
        if (mapTav.has(p.Tavolo)) {
          let tmp = mapTav.get(p.Tavolo);

          if (p.Ordine > tmp.Ordine) mapTav.set(p.Tavolo, p);
        } else {
          mapTav.set(p.Tavolo, p);
        }
      }
      listaPiatti = Array.from(mapTav.values());
      return listaPiatti;
    },
  },
};
</script>

<style scoped>
.exam-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  padding: 2vw;
}

.tables-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  justify-content: center;
  width: 100%;
}

.table-card {
  background: #f8f9fa;
  border: 0.2vw solid #3b5674;
  border-radius: 2vw;
  padding: 3vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-order {
  font-size: 2vw;
  color: #3b5674;
  margin-bottom: 1.5vw;
  text-align: left;
}

.table-number {
  font-size: 2.5vw;
  color: #3b5674;
  margin-bottom: 1.5vw;
  text-align: right;
}

.table-info {
  font-size: 2vw;
  margin-bottom: 1vw;
  font-weight: bold;
}

.title-info {
  font-size: 1vw;
}

@media (max-height: 700px) {
  .table-card {
    padding: 1vw;
    width: 80%;
    height: 50%;
  }
}

/* Media query per schermi con larghezza inferiore a 1230px */
@media (max-width: 1230px) {
  .tables-container {
    grid-template-columns: repeat(
      2,
      minmax(300px, 1fr)
    ); /* Le colonne hanno una larghezza minima di 300px */
  }
  .table-card {
    padding: 4vw;
    width: 80%;
  }
}

/* Media query per schermi con larghezza inferiore a 768px */
@media (max-width: 768px) {
  .tables-container {
    grid-template-columns: 1fr; /* Una colonna per schermi piccoli */
  }
  .table-card {
    padding: 5vw;
    width: 85%;
  }
  .table-order {
    font-size: 3.5vw;
  }
  .table-number {
    font-size: 3.5vw;
  }
  .table-info {
    font-size: 3vw;
  }
}

/* Media query per schermi con larghezza inferiore a 480px */
@media (max-width: 480px) {
  .tables-container {
    grid-template-columns: 1fr;
  }
  .table-card {
    width: 85%;
    padding: 5vw;
  }
  .table-order {
    font-size: 4vw;
  }
  .table-number {
    font-size: 4vw;
  }
  .table-info {
    font-size: 3.5vw;
  }
}
</style>
